/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
/* eslint-disable */
import axios from 'axios'
import router from '../router'
// import store from '../store'
import { Message } from 'element-ui'

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  Message.error({
    message: msg,
    duration: 1000
  })
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: '/',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 404:
      tip('请求的资源不存在')
      break
    case 500:
      tip('服务器错误')
      break
    default:
      console.log(other)
  }
}

// 创建axios实例
var instance = axios.create({
  timeout: 1000 * 100
})
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// instance.defaults.baseURL = VUE_APP_CURRENTMODE
instance.defaults.baseURL = 'https://backendapi.bonnidee.cn'
// instance.defaults.baseURL ='http://192.168.0.14:8088/'

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。q
    // const token = store.state.token
    // const token = loc alStorage.getItem('token')
    // token && (config.headers.Authorization = 'Bearer '+token)
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => {
	 return Promise.resolve(res.data)
  },
  // 请求失败
  error => {
    const { response } = error
	if(response.data.code == 401){
		// 登陆过期
		tip('登录过期，请重新登录')
		// window.clearVuexAlong()
		// localStorage.removeItem('agent_token')
		// localStorage.removeItem('agent_info')
		// store.commit('userInfo', null)
		// store.commit('token', null)
		// setTimeout(() => {
		//   toLogin()
		// }, 1000)
		return Promise.reject(response)
	}  else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // if (!window.navigator.onLine) {
      //   store.commit('changeNetwork', false)
      // } else {
      return Promise.reject(error)
      // }
    }
  }
)

export default instance
