import axios from '../utils/http' 


// 查询资质
export const QualificationsQuery = async (data) => await axios.get('/Qualifications/List', {params:data})

// 查询资质所有类型
export const QualificationTypesList = async (data) => await axios.get('/QualificationTypes/List', {params:data})

// 在线咨询
export const OnlineConsultingAdd = async (data) => await axios.post('/OnlineConsulting/Add', data)

// 首页全国服务客户数据
export const PlatformSetList = async (data) => await axios.get('/PlatformSet/List', {params:data})

// 发展历程
export const DevelopmentCourseList = async (data) => await axios.get('/DevelopmentCourse/List', {params:data})

// 公司环境
export const CompanyEnvironmentList = async (data) => await axios.get('/CompanyEnvironment/List', {params:data})

// 团队建设
export const TeamBuildingQuery = async (data) => await axios.get('/TeamBuilding/List', {params:data})

// 企业文化高管和核心团队
export const TeamList = async (data) => await axios.get('/Team/List', {params:data})

// 薪酬福利
export const SalaryList = async (data) => await axios.get('/Salary/List', {params:data})

// 人才发展
export const TalentList = async (data) => await axios.get('/Talent/List', {params:data})

// 目录管理
export const FrontendMenuList = async (data) => await axios.get('/FrontendMenu/List', {params:data})

// 解决方案
export const SolutionList = async (data) => await axios.get('/Solution/List', {params:data})

// 头部搜索框
export const ProductSearch = async (data) => await axios.get('/Product/Search', {params:data})

// 推荐产品
export const SearchRecommended = async (data) => await axios.get('/Product/SearchRecommended', {params:data})

// 招商加盟
export const JoinInAdd = async (data) => await axios.post('/JoinIn/Add', data)

//  动态类型
export const GroupDynamicTypeList = async (data) => await axios.get('/GroupDynamicType/List', {params:data})

//  动态列表
export const GroupDynamicFrontendQuery = async (data) => await axios.get('/GroupDynamic/FrontendQuery', {params:data})

//  动态年份列表
export const GroupDynamicYearList = async (data) => await axios.get('/GroupDynamic/YearList', {params:data})

//  动态年份详情
export const GroupDynamicFrontendDetail = async (data) => await axios.get('/GroupDynamic/FrontendDetail', {params:data})

//  轮播图管理
export const QueryIsShow = async (data) => await axios.get('/ShufflingFigure/QueryIsShow', {params:data})

//  招聘职位类别
export const HiringJobCategoryList = async (data) => await axios.get('/HiringJobCategory/List', {params:data})

//  招聘职位方向
export const HiringDirectionList = async (data) => await axios.get('/HiringDirection/List', {params:data})

//  招聘职位
export const HiringFrontendQuery = async (data) => await axios.get('/Hiring/FrontendQuery', {params:data})

//  招聘职位详情
export const HiringFrontendDetail = async (data) => await axios.get('/Hiring/FrontendDetail', {params:data})

//  限时秒杀及产品
export const TimeLimitSeckillsList = async (data) => await axios.get('/TimeLimitSeckillProducts/List', {params:data})

// 优惠券及产品
export const CouponsProductsList = async (data) => await axios.get('/CouponsProducts/List', {params:data})

// 根据类别查询上架产品信息 - 头部目录
export const QueryProductByCategory = async (data) => await axios.get('/Product/QueryProductByCategory', {params:data})